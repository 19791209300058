import React from "react";
import Navbar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import "../../styles/common.css";
import "../../styles/themes/dark.css";
import "../../styles/themes/light.css";
import "../../styles/themes/sepia.css";
import { ThemeContextProvider, useThemeContext } from "../ThemeContext";
import clsx from "clsx";
import styles from "./Layout.module.scss";

const Layout = ({
  location,
  placeholder,
  customClass,
  customNavClass,
  children,
  renderOutsideContainer,
}) => {
  const { theme } = useThemeContext();

  return (
    <div className={clsx(theme)}>
      <Navbar
        pathName={location.pathname}
        placeholder={placeholder === undefined ? true : placeholder}
        customNavClass={customNavClass}
        theme={theme}
      />
      {renderOutsideContainer ? renderOutsideContainer() : null}
      <div className={styles.container}>
        <div className={clsx(styles.wrapper, customClass)}>{children}</div>
      </div>
      <Footer theme={theme} />
    </div>
  );
};

const LayoutWrapper = (props) => (
  <ThemeContextProvider>
    <Layout {...props} />
  </ThemeContextProvider>
);

export default LayoutWrapper;
