import React from "react";
import { useThemeContext } from "../ThemeContext";
import MoonIcon from "../Icons/Moon";
import styles from "./ThemeSwitcher.module.scss";

const ThemeSwitcher = () => {
  const { theme, updateTheme } = useThemeContext();

  const onThemeBtnClick = () => {
    // based on the current theme, update the next theme
    if (theme === "dark") {
      updateTheme("light");
    } else if (theme === "light") {
      updateTheme("sepia");
    } else if (theme === "sepia") {
      updateTheme("dark");
    }
  };

  return (
    <button
      onClick={onThemeBtnClick}
      title={"Change Theme"}
      className={styles.switchBtn}
    >
      <MoonIcon />
    </button>
  );
};

export default ThemeSwitcher;
