import React, { Component, Fragment } from "react";
import Sidebar from "react-sidebar";
import ThemeSwitcher from "./ThemeSwitcher";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import SocialLinks from "./SocialLinks";
import "./Header.scss";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useThemeContext } from "../ThemeContext";
import Hamburger from "../Icons/Hamburger";
import clsx from "clsx";
import styles from "./Navbar.module.scss";

function SidebarContents({ pathName }) {
  const { theme } = useThemeContext();

  return (
    <div className={"sidebar-contents"}>
      <div className="logo">
        <AniLink
          cover
          bg={theme === "light" ? "#3355FF" : "#001681"}
          direction={"up"}
          duration={0.75}
          to={"/"}
          style={{ textDecoration: "none" }}
        >
          <Logo />
        </AniLink>
      </div>

      <div className="sidebar-navlinks">
        <NavLinks pathName={pathName} />
      </div>

      <div className="social-links">
        <SocialLinks />
      </div>
    </div>
  );
}

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarPlaceholderHeight: 100,
      sidebarOpen: false,
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.menuOpen = this.menuOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  menuOpen(event) {
    event.preventDefault();
    this.onSetSidebarOpen(true);
  }

  componentDidMount() {
    this.changeNavbarPlaceholderHeight();

    let logo = this.nav.querySelector(".logo"),
      _this = this;

    logo.addEventListener("load", function () {
      _this.changeNavbarPlaceholderHeight();
    });

    this.changeNavbarHeight();
  }

  changeNavbarHeight() {
    const scrollLimit = window.innerHeight * (5 / 100);

    window.addEventListener("scroll", function () {
      if (this.scrollY > scrollLimit) {
        document.querySelector("nav").classList.add("scrolled");
      } else {
        document.querySelector("nav").classList.remove("scrolled");
      }
    });
  }

  changeNavbarPlaceholderHeight() {
    let navBar = document.querySelector("nav");
    let navbarPlaceholderHeight = navBar.offsetHeight;
    this.setState({ navbarPlaceholderHeight: navbarPlaceholderHeight });
  }

  render() {
    const currentTheme = this.props.theme ?? "light";
    const bgColor = currentTheme === "light" ? "#3355FF" : "#001681";

    return (
      <Fragment>
        <Sidebar
          sidebar={<SidebarContents pathName={this.props.pathName} />}
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          sidebarClassName={"sidebar-content"}
          styles={{
            sidebar: {
              zIndex: 101,
              position: "fixed",
            },
            overlay: {
              zIndex: 100,
            },
            dragHandle: {
              position: "fixed",
              zIndex: "99999",
            },
          }}
        >
          <span />
        </Sidebar>

        <nav
          ref={(c) => (this.nav = c)}
          className={clsx(this.props.customNavClass, styles.navbarContainer)}
        >
          <div className={styles.navbarInner}>
            <button href="#mobilenav" id="menu-open" onClick={this.menuOpen}>
              <span className="icon">
                <Hamburger />
              </span>
            </button>
            <AniLink
              cover
              direction={"up"}
              bg={bgColor}
              duration={0.75}
              to="/"
              style={{ textDecoration: "none", marginTop: "10px" }}
            >
              <Logo />
            </AniLink>
            <NavLinks pathName={this.props.pathName} />
            <span className="switcher">
              <ThemeSwitcher />
            </span>{" "}
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default Navbar;
