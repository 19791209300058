import React, { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <button className={"logo"}>
        {"</>"}
        <span>Raj Rajhans</span>
      </button>
    );
  }
}

export default Logo;
