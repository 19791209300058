import React, { Component } from "react";
import GithubIcon from "../Icons/social-icons/GithubFooter";
import TwitterIcon from "../Icons/social-icons/Twitter";
import LinkedinIcon from "../Icons/social-icons/Linkedin";
import InstagramIcon from "../Icons/social-icons/Instagram";

class SocialLinks extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center ">
            <ul className="list-inline social-links">
              <li>
                <a
                  href="https://github.com/rajrajhans"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="btn-social btn-outline"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Github"
                >
                  <GithubIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/_rajrajhans"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="btn-social btn-outline"
                  title="Twitter"
                >
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/rajrajhans/"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="btn-social btn-outline"
                  title="LinkedIn"
                >
                  <LinkedinIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/_rajrajhans/"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="btn-social btn-outline"
                  title="Instagram"
                >
                  <InstagramIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialLinks;
